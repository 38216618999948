/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

.welcome {
    color: white;
}

.welcome.navbar-text {
    color: white;
}

.left-section {
    background-color: #363636;
}

ul.custom-menu {
    list-style-type: none; /* Remove bullet points */
    padding-top: 50px;
    padding-left: 10px; /* Remove default padding */
}

.custom-menu li {
    padding-bottom: 6px; /* Space between items */
}

.custom-menu ul {
    padding-left: 20px; /* Indent for nested lists */
}

.custom-menu li a {
    text-decoration: none;
    color: white;
}

.top-nav-bar {
    background-image: url("../../images/banner-2x.png");
    background-position: left top;
    background-repeat: no-repeat;
}

.input-table .row {
    margin-bottom: 5px;
}

.even-row {
    background-color: #f8f9fa; /* Light gray for even rows, for example */
}

.odd-row {
    background-color: #ffffff; /* White for odd rows */
}

.scrollable-wrapper {
    padding-top: 12px;
    padding-bottom: 12px;
}

.scrollable-container {
    max-height: 300px;
    overflow-y: auto; /* Enable vertical scrolling */
}

li a.active {
    font-weight: bold;
}

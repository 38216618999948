.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.app-container {
    display: flex;
    height: 100vh;
}

.left-menu {
    /* Style your left menu */
    width: 200px;
    background-color: #333;
    color: white;
    /* ... other styles */
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.top-bar {
    /* Style your top bar */
    background-color: #555;
    color: white;
    /* ... other styles */
}

.member-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    /* ... other styles */
}

/* Add more styling as needed */
.basic-layout {
    flex-grow: 1;
    overflow-y: auto;
}

.no-padding {
    padding: 0 !important;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1050; /* Make sure this is higher than other elements */
}

#footer-wrapper {
    border-top: 1px solid #1b1b1b;
    background-color: #1b1b1b;
    padding: 60px;
    color: white;
}
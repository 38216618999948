/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

table.table-smaller-rows td,
table.table-smaller-rows th {
    padding: 0.3rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    /* Reduce padding for table cells */
}

.table-smaller-rows .button-smaller {
    padding: 0.25rem 0.5rem; /* Reduce padding for buttons */
    font-size: 0.875rem; /* Smaller font size for buttons */
    line-height: 1.5;
}

.botton-pagination ul.pagination {
    margin-bottom: 0;
}

.table-smaller-rows td, .table-smaller-rows th {
    padding: 4px; /* Adjust the padding to make rows narrower */
    text-wrap: normal;
}

.table-smaller-rows td.wordBreak {
    /*word-break: break-word;*/
}

.table-smaller-rows .allBreak {
    word-break: break-all;
}

.table-smaller-rows .btn-extra-sm {
    padding: 0.2rem 0.4rem;
    font-size: 0.75rem;
    line-height: 1.5;
}